import template from "./home.html";

import Vue from "vue";
import Component from "vue-class-component";
import NavigationBar from '../../components/navigationBar/NavigationBar';
import Footer from '../../components/footer/Footer';

@Component({
    template,
    components: {
        NavigationBar,
        Footer
    }
})
export default class HomeController extends Vue
{
 
}